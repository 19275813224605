/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Heading, Text } from 'theme-ui'

export const BlurbRow = ({ title, blurbs }) => {
  return (
    <Main style={{ margin: '0 auto' }}>
      <Box sx={{ padding: '40px 36px' }}>
        <Heading
          as="h2"
          sx={{
            textTransform: 'uppercase',
            fontSize: '21px',
            letterSpacing: '2.5px',
            margin: '0 0 20px',
          }}
        >
          {title}
        </Heading>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {blurbs.map((b) => {
            return (
              <Box
                key={b.title}
                sx={{
                  width: ['100%', null, '50%'],
                  marginBottom: '1.5rem',
                  paddingRight: [0, null, '2rem'],
                }}
              >
                <Heading
                  as="h3"
                  sx={{
                    fontSize: '16px',
                    letterSpacing: '1px',
                    margin: '0 0 9px',
                  }}
                >
                  {b.title}
                </Heading>
                <Text sx={{ lineHeight: 1.3 }}>{b.text}</Text>
              </Box>
            )
          })}
        </Box>
      </Box>
    </Main>
  )
}
