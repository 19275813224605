/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Heading, Image, Text } from 'theme-ui'

import OtherProductShot from '../images/otherProductShot.png'

export const SecondaryHero = ({ title, text, image = OtherProductShot }) => {
  return (
    <Main style={{ margin: '0 auto' }}>
      <Box
        sx={{
          minHeight: '560px',
          display: 'flex',
          flexDirection: ['column', null, null, 'row'],
          alignItems: 'center',
          margin: ['0 auto', null, null, 0],
          padding: ['60px 0 0', null, null, '24px'],
        }}
      >
        <Box
          sx={{
            maxWidth: ['90%', null, '450px'],
            margin: ['0 auto 50px', null, null, '0 25px 0 0'],
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            overflow: 'hidden',
          }}
        >
          <Heading
            as="h1"
            sx={{
              textTransform: 'uppercase',
              fontSize: '21px',
              letterSpacing: '2.5px',
              margin: '0 0 16px',
            }}
          >
            {title}
          </Heading>
          {text}
        </Box>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Image
            alt="Guess the Rose App Preview"
            src={image}
            sx={{
              zIndex: 100,
              position: 'relative',
              maxWidth: ['90%', null, '400px'],
            }}
          />
        </Box>
      </Box>
    </Main>
  )
}
