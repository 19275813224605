/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import Seo from '@widgets/Seo'
import React, { useEffect, useState } from 'react'
import { Box, Text } from 'theme-ui'

import { BlurbImage } from '../components/BlurbImage'
import { BlurbRow } from '../components/BlurbRow'
import { Countdown } from '../components/Countdown'
import { FAQs } from '../components/FAQs'
import { FooterCTA } from '../components/FooterCTA'
import { LoneRose } from '../components/LoneRose'
import { SecondaryHero } from '../components/SecondaryHero'
import HowItWorks from '../images/howItWorks.png'

const Page = ({ path }) => {
  const [meta, setMeta] = useState()

  useEffect(() => {
    const fetchMeta = async () =>
      fetch(
        'https://firestore.googleapis.com/v1/projects/guess-the-rose/databases/(default)/documents/meta/meta',
      )
        .then((resp) => resp.json())
        .then((resp) => {
          setMeta(resp)
        })

    fetchMeta()
  }, [])

  return (
    <>
      <Seo pageUrl={path} title="How it Works" />
      <Main style={{ margin: '0 auto', padding: 0 }} variant="compact">
        <Box sx={{ padding: '40px 36px' }}>
          <Box
            sx={{
              background: 'linear-gradient(225deg, #FF044B 0%, #C01CA1 100%)',
              borderRadius: '10px',
              padding: '1px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                background: 'white',
                borderRadius: '10px',
                display: 'flex',
                px: '4',
                py: '3',
              }}
            >
              <Box
                sx={{
                  mr: ['2', null, '4'],
                }}
              >
                <svg
                  focusable="false"
                  height="32"
                  role="presentation"
                  viewBox="0 0 52 56"
                  width="32"
                >
                  <g>
                    <path
                      d="M14.1309 33C12.1876 35.6118 15.9484 37.204 11.6131 45.4928L5 55.7054C5 55.7054 7.46333 56.4167 8.10571 55.6288C13.4926 49.0213 16.4147 42.7253 19.9576 42.0851C23.7208 41.4061 26.3838 40.5247 27 39.106C27 39.1061 20.4111 39.5147 14.1309 33Z"
                      fill="url(#paint0_linear)"
                    ></path>
                    <path
                      d="M44.06 7.27624C45.1979 8.07024 46.9176 9.26997 45.5309 11.9035L45.5225 11.9184C42.8629 16.3385 43.1254 24.784 43.3383 28C44.2526 25.7915 46.1401 23.6201 47.975 21.5096C50.117 19.0455 52.1403 16.7181 51.9924 14.919C51.9389 14.2687 51.6004 13.7068 50.9576 13.2007C49.5596 12.0151 49.1465 10.7864 48.6236 9.23056C48.1777 7.90432 47.6725 6.40122 46.4268 4.42755C45.8375 3.49365 45.2809 3 44.8175 3C43.9658 3 43.3206 4.67892 43 6.25315C43.0614 6.57949 43.5471 6.91831 44.06 7.27624Z"
                      fill="url(#paint1_linear)"
                    ></path>
                    <path
                      d="M30.0036 41.2582C29.7823 41.0845 29.5195 41 29.2005 41C28.1422 41 26.894 41.9603 26.7553 42.0698L26.684 42.1261L26.6729 42.1267C26.2505 42.4124 26.0248 42.637 26.0019 42.7944C25.9785 42.9556 26.1774 43.1939 26.429 43.4955C27.1673 44.3797 28.5003 45.9768 28.075 50C30.6609 46.9306 31.0963 44.7161 30.9842 43.3691C30.8765 42.0755 30.2625 41.4619 30.0036 41.2582Z"
                      fill="url(#paint2_linear)"
                    ></path>
                    <path
                      d="M7.78827 34C7.78877 34 7.78877 34 7.78926 34C8.80998 34 9.88796 33.8424 11 33.5313C10.8613 33.3918 10.6835 33.2065 10.4538 32.9598C9.95419 32.423 9.19381 31.5567 8.40892 30.4779C7.025 28.5758 5.31481 25.6545 5.02629 22.5389C4.63898 18.59 2.06772 18 0.569565 18C0.421372 18 0.222488 18.006 0 18.0283C0.836371 19.3896 2.8288 23.1961 0.843542 25.8302C0.14889 26.7304 0.0693158 28.1193 0.628722 29.5482C1.43283 31.6019 3.79066 34 7.78827 34Z"
                      fill="url(#paint3_linear)"
                    ></path>
                    <path
                      d="M13 35.0026C12.8872 35.0006 12.7849 35 12.7042 35C11.7407 35 9.36232 35.089 8.0888 35.9122C6.51004 36.9408 2.97672 39.6957 4.28479 43.0409C4.50064 43.5881 4.63968 43.8625 4.72544 44C4.80661 43.8233 4.90578 43.5235 4.99638 43.2497C5.35791 42.1571 5.90462 40.506 7.23165 39.2787C8.37786 38.2122 9.49133 38.0479 10.3859 37.9159C11.6642 37.7269 12.5246 37.5998 13 35.0026Z"
                      fill="url(#paint4_linear)"
                    ></path>
                    <path
                      d="M30.2695 36.9999C34.6124 36.9997 39.8501 35.3371 40.1399 27.4186C40.188 26.0986 40.2279 24.9062 40.2641 23.8256C40.5351 15.7449 40.5995 13.8251 42.2835 11.4346C42.9876 10.4202 43.1909 9.34577 42.8126 8.63236C42.5672 8.16937 42.1048 7.92471 41.4758 7.92471C40.8284 7.92471 40.0338 8.19203 39.1777 8.69792C36.8256 10.0779 34.9563 10.6931 33.1157 10.6931C32.3166 10.6931 31.4894 10.5777 30.5124 10.3298C30.1655 10.2419 29.8225 10.1973 29.4933 10.1973C27.4589 10.1973 25.6881 11.9176 24.2301 15.3104C23.6623 16.6275 23.1511 17.7484 22.7 18.7373C21.5793 21.1942 20.6942 23.1349 19.7108 26.3583C19.6822 26.4667 19.5994 26.5315 19.5551 26.5664L19.5119 26.6012C19.2245 26.8031 18.9812 26.9013 18.7684 26.9013C18.5777 26.9013 18.416 26.8241 18.3007 26.6778C18.1111 26.4376 17.9485 25.9423 18.3766 24.2302C18.5412 23.5707 18.5244 23.3587 18.514 23.3058L18.4358 23.2226L18.4657 23.0821C19.5581 17.9533 22.2923 12.9153 24.4869 9.9107C26.4428 7.21374 27.8081 7.36907 29.658 7.56475C30.1987 7.6219 30.8117 7.6868 31.5021 7.6868C33.1859 7.6868 34.8694 7.29544 36.8003 6.45523C39.0351 5.46301 39.4513 4.73612 39.4066 4.30109C39.2603 2.87804 34.5117 1.83641 32.4839 1.39154C32.2632 1.34312 32.0618 1.29901 31.8855 1.25899C31.2342 1.11748 30.562 0.882992 29.912 0.656247C28.9447 0.318726 28.0311 0 27.2594 0C26.4916 0 25.5628 0.278043 25.1918 2.44589C24.3931 7.03741 22.8065 8.66454 18.1213 11.5317C13.9709 14.0664 11.3123 18.7248 11.0089 23.9929C10.9397 25.1871 11.2728 26.3582 11.9992 27.4738L12.0903 27.6139L12.0803 27.6317C13.6788 30.3725 20.7181 35.0597 25.0576 36.2414C25.0772 36.247 25.0912 36.2522 25.1051 36.2574C26.0531 36.5342 27.9909 37 30.2684 37C30.2688 36.9999 30.2691 36.9999 30.2695 36.9999Z"
                      fill="url(#paint5_linear)"
                    ></path>
                    <path
                      d="M9.41485 8.11213C9.1098 13.6975 8.98425 17.9105 9.00157 20C9.48915 18.2304 10.1939 16.3268 11.5213 14.2623C13.2009 11.671 17.5444 8.91937 19.9839 7.50973C21.4971 6.64452 23.1054 4.51445 22.9946 2.95785C22.9406 2.19996 22.4835 1.64378 21.636 1.30454C21.13 1.09948 20.6992 1 20.3175 1C19.5965 1 19.1717 1.36794 18.722 1.75755C18.2703 2.14889 17.8032 2.55337 17.059 2.55337C16.809 2.55337 16.5492 2.50773 16.2649 2.41355C15.3075 2.09737 14.4852 1.94371 13.7511 1.94371C11.0989 1.94371 9.63997 4.01903 9.41485 8.11213Z"
                      fill="url(#paint6_linear)"
                    ></path>
                    <path
                      d="M50.8147 30.0898C50.2617 30.0477 49.6348 30 48.9471 30C46.8906 30 43.8955 30.3777 41.6382 33.6437C39.2784 37.0276 36.6569 37.5328 35.3044 37.5328C35.1977 37.5328 35.0961 37.5296 35 37.5243C36.3339 38.5042 37.7983 39 39.363 39C43.0461 39 46.2574 36.2712 47.2681 34.4272C48.1842 32.7999 49.9459 31.5978 51.1114 30.8023C51.4556 30.5674 51.8364 30.3073 52 30.1544C51.962 30.1516 51.9142 30.1494 51.8545 30.149C51.5618 30.1467 51.2156 30.1203 50.8147 30.0898Z"
                      fill="url(#paint7_linear)"
                    ></path>
                    <defs>
                      <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint0_linear"
                        x1="18.979"
                        x2="-7.77896"
                        y1="37.9431"
                        y2="75.9209"
                      >
                        <stop stopColor="#C21C8A"></stop>
                        <stop offset="1" stopColor="#F50F4C"></stop>
                      </linearGradient>
                      <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint1_linear"
                        x1="45.8442"
                        x2="51.9082"
                        y1="21.7259"
                        y2="17.6433"
                      >
                        <stop stopColor="#C21C8A"></stop>
                        <stop offset="1" stopColor="#F50F4C"></stop>
                      </linearGradient>
                      <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint2_linear"
                        x1="28.0741"
                        x2="12.9843"
                        y1="46.2747"
                        y2="65.7423"
                      >
                        <stop stopColor="#C21C8A"></stop>
                        <stop offset="1" stopColor="#F50F4C"></stop>
                      </linearGradient>
                      <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint3_linear"
                        x1="7.39104"
                        x2="-0.9329"
                        y1="29.8934"
                        y2="24.3868"
                      >
                        <stop stopColor="#C21C8A"></stop>
                        <stop offset="1" stopColor="#F50F4C"></stop>
                      </linearGradient>
                      <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint4_linear"
                        x1="13.1345"
                        x2="-10.2993"
                        y1="32.5804"
                        y2="60.2106"
                      >
                        <stop stopColor="#C21C8A"></stop>
                        <stop offset="1" stopColor="#F50F4C"></stop>
                      </linearGradient>
                      <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint5_linear"
                        x1="22.6996"
                        x2="37.3346"
                        y1="34.3668"
                        y2="4.50168"
                      >
                        <stop stopColor="#C21C8A"></stop>
                        <stop offset="1" stopColor="#F50F4C"></stop>
                      </linearGradient>
                      <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint6_linear"
                        x1="17.4426"
                        x2="10.6074"
                        y1="17.496"
                        y2="1.68703"
                      >
                        <stop stopColor="#C21C8A"></stop>
                        <stop offset="1" stopColor="#F50F4C"></stop>
                      </linearGradient>
                      <linearGradient
                        gradientUnits="userSpaceOnUse"
                        id="paint7_linear"
                        x1="35.3779"
                        x2="48.9045"
                        y1="35.7708"
                        y2="28.5735"
                      >
                        <stop stopColor="#C21C8A"></stop>
                        <stop offset="1" stopColor="#F50F4C"></stop>
                      </linearGradient>
                    </defs>
                  </g>
                </svg>
              </Box>
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flex: '1',
                  justifyContent: 'space-between',
                }}
              >
                <Text>
                  Gameplay starts with episode 2. Relaaaax. The first episode is
                  for drinking wine and scouting out your favorite contestants.
                  This is also a great chance to get everyone signed up to your
                  league!
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
      </Main>
      <SecondaryHero
        image={HowItWorks}
        text={
          <Box>
            <Text
              sx={{
                margin: '0 auto',
                marginBottom: '16px',
                fontSize: '16px',
                lineHeight: 1.2,
              }}
            >
              <b>
                Guess the Rose gameplay is simple: Root for your sweetheart all
                season long and make a series of fun weekly picks.
              </b>
              <br />
              After each episode, we score every entry and add the points
              accrued to the total for the season. Leaderboards for each league
              are updated immediately so you always know how you're doing.
            </Text>
          </Box>
        }
        title="How it Works"
      />
      <BlurbRow
        blurbs={[
          {
            title: 'The Trial Period',
            text:
              "We treat the first episode of the show as a trial period where there are no lineups or questions. This gives newcomers a chance to get familiar with the contestants, and also makes sure someone doesn't choose a contestant that gets bounced immediately. This is a great chance to get everyone signed up to your league.",
          },
          {
            title: 'One True Love',
            text:
              "The day after the premier, we lock all groups and you select your entry's one true love. This is who you think the star of the season will select in the finale and who you root for throughout the season. Choosing the correct contestant will you give extra points at the end of the season.",
          },
          {
            title: 'Weekly Picks',
            text:
              "Every week, you will create a lineup of contestants that you think will make through the next episode and  answer questions based on what's happening in the show. The weekly lineup and questions are all assigned a point value. You receive points for every character that makes it through the episode and for every question you answer correctly.",
          },
          {
            title: 'Scoring',
            text:
              "After each episode, we score every entry and add the points accrued to the total for the season. Whoever has the most points at the end of the show wins. Leaderboards for each league is updated immediately so you always know how you're doing.",
          },
        ]}
        title="Getting Started"
      />
      {meta &&
      meta.fields &&
      meta.fields.currentSeasonStatus &&
      meta.fields.currentSeasonStatus.stringValue === 'preseason' ? (
        <Countdown />
      ) : null}
      <BlurbImage
        blurbs={[
          {
            text: 'Weekly Rose Predictions',
            subtext: '+5 points per each correct pick',
          },
          {
            text: 'Weekly Questions',
            subtext: '+10 points per each correct answer',
          },
          { text: 'One true love selection', subtext: '+50 points' },
        ]}
        text="With Guess the Rose, you're never truly out of it! To start the season, everyone chooses who they think will be the final rose recipient. That's who you are rooting for the entire season. Every week, you create a team of people that you think will make it through the episode and also answer topical questions. At the end of the season, most points win!"
        title="Scoring"
      />
      <FAQs />
      <LoneRose />
      <FooterCTA />
    </>
  )
}

export default Page
