import { useTheme } from '@guess-the-rose/web-shared'
/* eslint-disable import/no-unresolved */
import { Main } from '@layout'
import React from 'react'
import { Box, Heading, Image, Text } from 'theme-ui'

import HeroImage from '../images/heroImage.png'
import { withChakra } from './withChakra'

export const BlurbImage = withChakra(
  ({ title, text, blurbs, image = HeroImage }) => {
    const theme = useTheme()

    return (
      <Box
        sx={{
          minHeight: '190px',
          background: theme.colors.brand.sectionSecondaryBackground,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Main
          style={{ margin: '0 auto', maxWidth: '976px', padding: 0 }}
          variant="compact"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: ['column', null, 'row'],
              padding: '52px 36px',
            }}
          >
            <Box
              sx={{
                padding: [0, null, '0 3rem 0 0'],
              }}
            >
              <Heading
                as="h2"
                sx={{
                  textTransform: 'uppercase',
                  fontSize: '21px',
                  letterSpacing: '2.5px',
                  margin: '0 0 20px',
                  color: 'white',
                }}
              >
                {title}
              </Heading>
              <Text sx={{ color: 'white', marginBottom: '2rem' }}>{text}</Text>
              <Box sx={{ marginBottom: '3rem' }}>
                {blurbs.map((b) => {
                  return (
                    <Box key={b.text} sx={{ marginBottom: '1rem' }}>
                      <Text sx={{ color: 'white', lineHeight: 1 }}>
                        {b.text}
                      </Text>
                      <Text sx={{ color: '#FF0458', fontStyle: 'italic' }}>
                        {b.subtext}
                      </Text>
                    </Box>
                  )
                })}
              </Box>
            </Box>
            <Box sx={{ flexShrink: 0 }}>
              <Image
                alt="Guess the Rose app screenshot"
                src={image}
                sx={{ maxWidth: '250px' }}
              />
            </Box>
          </Box>
        </Main>
      </Box>
    )
  },
)
